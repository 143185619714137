<template>
  <div
    v-if="!loading"
    class="pt-1"
  >
    <b-card class="issues-card">
      <div class="page-title">
        City 2022 Issues
      </div>
      <div class="block-wrapper">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <div>
              <ul class="issue-data-list list-unstyled">
                <li
                  v-for="(item, issueKey) in issuesData"
                  :key="'issue-'+ issueKey"
                >
                  <span
                    :style="'background-color:'+defaultColors[issueKey]"
                    class="color-indicator"
                  />
                  <span class="text-wrapper label-text">{{ defaultLabels[issueKey] }}:</span><span class="text-wrapper content-text">{{ issuesData[issueKey] || '-' }}</span>
                </li>
              </ul>
            </div>
            <a
              target="_blank"
              href="https://apolloissues.gorgias.com/app/tickets/311293/city22-damage"
            >
              <b-button class="show-button">View Gorgias</b-button>
            </a>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <div class="chart-wrapper">
              <card-statistic-circle-chart-colors
                :series="issueStats.series"
                :labels="issueStats.labels"
                :titles="issueStats.titles"
                :actual-numbers="issueStats.actualNumbers"
                :colors="colors"
                :custom-total-value="1100"
                :custom-total="true"
                :height="350"
                title="Total defect"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
  <div
    v-else
    class="pt-1"
  >
    <b-card>
      <div class="d-flex justify-content-center">
        <b-spinner label="Loading..." />
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BButton, BRow, BCol, VBTooltip,
} from 'bootstrap-vue'

import CardStatisticCircleChartColors from '@/views/components/statistics/CardStatisticCircleChartColors.vue'
import './scss/issues.scss'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    CardStatisticCircleChartColors,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: true,
      issuesData: null,
      issueStats: {},
      colors: [],
      defaultLabels: {
        charger: 'Charger',
        throttle: 'Throttle',
        folding: 'Folding Mechanism',
        loose: 'Loose Directional Stem',
        wires: 'Pinched Wires',
        not_turning_on: 'Not turning on',
        battery: 'Battery',
        throttle_not_engaging: 'Throttle not engaging',
      },
      defaultColors: {
        charger: '#a83232',
        throttle: '#a89c32',
        folding: '#32a83a',
        loose: '#32a89e',
        wires: '#5332a8',
        not_turning_on: '#ff0000',
        battery: '#f4e8c1',
        throttle_not_engaging: '#706993',
      },
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.$http.get(this.$resources.rides.cityissues).then(response => {
        const { status, data } = response
        if (status === 200) {
          delete (data.charger)
          this.issuesData = data
          const values = Object.values(this.issuesData)
          this.getColors(data)
          const total = parseFloat(values.reduce((a, b) => a + b))
          this.issueStats = {
            actualNumbers: Object.values(this.issuesData),
            series: this.getValues(values, total),
            titles: this.getValues(values, 1100),
            labels: this.getLabels(data),
            total,
          }
          this.loading = false
        }
      })
    },
    getValues(data, total) {
      return data.map(value => (parseFloat(value / total) * 100))
    },
    getLabels(data) {
      return Object.keys(data).map(item => this.defaultLabels[item])
    },
    getColors(data) {
      Object.keys(data).map(item => this.colors.push(this.defaultColors[item]))
    },
  },
}
</script>
